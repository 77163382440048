exports.onInitialClientRender = () => {
  require('lazysizes');
  require('lazysizes/plugins/optimumx/ls.optimumx');
  require('lazysizes/plugins/object-fit/ls.object-fit.js');
  require('lazysizes/plugins/parent-fit/ls.parent-fit');
  require('lazysizes/plugins/rias/ls.rias');
  require('lazysizes/plugins/bgset/ls.bgset');
  require('lazysizes/plugins/respimg/ls.respimg');
  require('lazysizes/plugins/unveilhooks/ls.unveilhooks.js');
  console.log("ReactDOM.render has executed")
}
exports.onServiceWorkerUpdateReady= () => window.location.reload(true);
